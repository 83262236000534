// 开启 HMR 支持
import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import Home from '@/Home';
import Introduce from '@views/Introduce';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function setRem () {

  const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  const htmlDom = document.getElementsByTagName('html')[0];
  htmlDom.style.fontSize= (htmlWidth / 120) + 'px';
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem

if (window.HUYASdk) {
  window.HUYASdk.setShareInfo({
    isShow: 1,
    title: '云视听虎电竞',
    content: '虎牙直播TV版，大屏蓝光极致体验',
    url: 'https://tv.huya.com/index.html',
    shareUrl: 'https://tv.huya.com/index.html',
    imageUrl: 'https://diy-assets.msstatic.com/hudownload/icon.png'
  });
}

function checkIsMobile() {
  const ua = navigator.userAgent;
  const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
  const isIphone =!ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
  const isAndroid = ua.match(/(Android)\s+([\d.]+)/);
  if (isIphone || isAndroid) location.href = 'https://hd.huya.com/h5/hudownloadh5/index.html#/'
}

checkIsMobile();

ReactDOM.render((
  <Router basename={location.pathname}>
    <Switch>
      <Route path="/introduce/:tvName">
        <Introduce/>
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </Router>
), document.getElementById('root'));
