import React, { useEffect } from 'react';

function useBuriedPoint(whichTv : string) {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.report({
      eid: 'sys/pageshow/ysthdjxzy',
      eid_desc: '系统/曝光/云视听虎电竞下载页',
      prop: {
        platform: 'web',
        position: whichTv
      },
    });
  }, [])

}

export default useBuriedPoint;