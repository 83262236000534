import React from 'react';

export const handleOnClick = (whichTv) => () => {
  window.report({
    eid: 'usr/click/ysthdjxzy/download',
    eid_desc: '用户/点击/云视听虎电竞下载页/下载',
    prop: {
      position: whichTv,
    },
  });
}

const tvIntroduceData = {
  mi: {
    title: '小米电视安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          <>在首页点击<br/>“精选”/“应用”-&gt;“我的应用”-&gt;“应用商店”，搜索“YSTHDJ”下载即可</>,
          'img',
          'img',
          'img',
        ]
      },
      {
        title: '方式一：使用U盘安装',
        label: [
          <>
            <p>1.下载apk包</p>
            <a
              onClick={handleOnClick('mi')} 
              href="http://rel.huya.com/apk/tv?key=tv0001" 
              className="downloadBtn"
              style={{marginBottom: '0px'}}
            >
              立即下载
              <div className="hoveur"></div>
            </a>
          </>,
          '2.将apk包拷贝到U盘中，将U盘插入电视，点击“打开U盘”，找到U盘中的安装包安装即可',
          'img',
          <>或者在首页点击<br/>“应用”-&gt;“我的应用”-“高清播放器”,找到U盘中的云视听虎电竞点击安装即可</>,
          'img',
          'img',
          'img',
        ]
      }
    ]
  },
  hisense: {
    title: '海信电视安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          <>在首页点击<br/>“应用”-&gt;“应用搜索”，搜索“YSTHDJ”下载即可</>,
          'img',
        ]
      },
      {
        title: '方式二：使用U盘安装',
        label: [
          <>
            <p>1.下载apk包</p>
            <a
              onClick={handleOnClick('hisense')} 
              href="http://rel.huya.com/apk/tv?key=tv0001" 
              className="downloadBtn"
              style={{marginBottom: '0px'}}
            >
              立即下载
              <div className="hoveur"></div>
            </a>
          </>,
          <>2.将apk包拷贝到U盘中，将U盘插入电视，点击首页键选择<br/>“应用”-&gt;“我的应用”-&gt;“管理”-&gt;“U盘助手”，找到U盘中的安装包点击安装即可</>,
          'img',
          'img',
          'img',
        ]
      }
    ]
  },
  TCL:{
    title: 'TCL电视安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          <>在首页点击<br/>“应用”-&gt;“应用搜索”，搜索“YSTHDJ”下载即可</>,
          'img',
          '或者在首页点击“精选”，遥控下拉到“优质应用推荐”板块，找到云视听虎电竞点击安装即可',
          'img',
          'img',
        ]
      },
      {
        title: '方式二：使用U盘安装',
        label: [
          <>
            <p>1.下载apk包</p>
            <a
              onClick={handleOnClick('TCL')} 
              href="http://rel.huya.com/apk/tv?key=tv0001" 
              className="downloadBtn"
              style={{marginBottom: '0px'}}
            >
              立即下载
              <div className="hoveur"></div>
            </a></>,
          '2.将apk包拷贝到U盘中，将U盘插入电视',
          'img',
          '3.首页点击“应用”->“我的应用”里面打开“电视卫视”',
          'img',
          '4.点击“应用管理”-“极速安装”-“我的U盘”，找到U盘中的云视听虎电竞点击安装即可',
          'img',
          'img',
        ]
      }
    ]
  },
  skyworth:{
    title: '创维电视安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          <>在首页点击<br/>“应用”-&gt;“应用搜索”，搜索“YSTHDJ”下载即可</>,
          'img',
          'img',
        ]
      },
      {
        title: '方式二：使用U盘安装',
        label: [
          <>
            <p>1.下载apk包</p>
            <a
              onClick={handleOnClick('skyworth')} 
              href="http://rel.huya.com/apk/tv?key=tv0001" 
              className="downloadBtn"
              style={{marginBottom: '0px'}}
            >
              立即下载
              <div className="hoveur"></div>
            </a>
          </>,
          <>2.将apk包拷贝到U盘中，将U盘插入电视，点击首页键选择<br/>“应用”-&gt;“我的应用”-&gt;“管理”-&gt;“U盘助手”，找到U盘中的安装包点击安装即可</>,
          'img',
          'img',
          'img',
        ]
      }
    ]
  },
  skyworthBox:{
    title: '创维盒子安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          <>在首页点击<br/>“应用”-&gt;“全部应用”-&gt;“应用商店”，搜索“YSTHDJ”下载即可</>,
          'img',
          'img',
          'img'
        ]
      },
      {
        title: '方式二：使用U盘安装',
        label: [
          <>
            <p>1.下载apk包</p>
            <a
              onClick={handleOnClick('skyworthBox')} 
              href="http://rel.huya.com/apk/tv?key=tv0001" 
              className="downloadBtn"
              style={{marginBottom: '0px'}}
            >
              立即下载
              <div className="hoveur"></div>
            </a>
          </>,
          '2.将apk包拷贝到U盘中，将U盘插入电视，电脑检测到U盘，点击“文件管理”',
          'img',
          '3.点击“应用”，找到云视听虎电竞点击安装即可',
          'img',
        ]
      }
    ]
  },
  konka: {
    title: '康佳电视安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          <>在首页点击<br/>“应用”-&gt;“应用商城”，搜索“YSTHDJ”下载即可</>,
          'img',
          '或者在首页点击“应用”，应用推荐里找到云视听虎电竞，点击下载即可',
          'img',
        ]
      },
      {
        title: '方式二：使用U盘安装',
        label: [
          <>
            <p>1.下载apk包</p>
            <a
              onClick={handleOnClick('konka')} 
              href="http://rel.huya.com/apk/tv?key=tv0001" 
              className="downloadBtn"
              style={{marginBottom: '0px'}}
            >
              立即下载
              <div className="hoveur"></div>
            </a>
          </>,
          '2.将apk包拷贝到U盘中，将U盘插入电视，电脑检测到U盘，点击打开',
          'img',
          '3.在电视上找到U盘中的安装包进行安装即可',
          'img',
        ]
      }
    ]
  },
  letv:{
    title: '乐视电视安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          <>在首页点击<br/>“应用”-&gt;“应用商城”，搜索“YSTHDJ”下载即可</>,
          'img',
          'img',
        ]
      },
      {
        title: '方式二：使用U盘安装',
        label: [
          <>
            <p>1.下载apk包</p>
            <a
              onClick={handleOnClick('letv')} 
              href="http://rel.huya.com/apk/tv?key=tv0001" 
              className="downloadBtn"
              style={{marginBottom: '0px'}}
            >
              立即下载
              <div className="hoveur"></div>
            </a>
          </>,
          '2.将apk包拷贝到U盘中，将U盘插入电视，电脑检测到U盘，点击打开',
          'img',
          '3.	点击“存储设备”->“我的U盘”，找到U盘中的 云视听虎电竞安装即可',
          'img',
          'img',
        ]
      }
    ]
  },
  changhong:{
    title: '长虹电视安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          '在首页点击“应用”->“应用商店”->“找应用”，搜索“YSTHDJ”下载即可',
          'img',
          'img',
          'img',
        ]
      }
    ]
  },
  huawei:{
    title: '华为电视安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          <>在首页点击<br/>“应用”-&gt;“应用市场”，搜索“YSTHDJ”下载即可</>,
          'img',
          'img',
        ]
      },
      {
        title: '方式二：使用U盘安装',
        label: [
          <>
            <p>1.下载apk包</p>
            <a
              onClick={handleOnClick('huawei')} 
              href="http://rel.huya.com/apk/tv?key=tv0001" 
              className="downloadBtn"
              style={{marginBottom: '0px'}}
            >
              立即下载
              <div className="hoveur"></div>
            </a>
          </>,
          '2.将apk包拷贝到U盘中，将U盘插入电视',
          <>3.在首页点击<br/>“应用”-&gt;“文件管理器”-“我的U盘”，找到云视听虎电竞点击安装即可</>,
          'img',
          'img',
        ]
      }
    ]
  },
  tianmao:{
    title: '天猫魔盒安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          <>在首页点击<br/>“应用”-&gt;“应用搜索”，搜索“云视听虎电竞”下载即可</>,
          'img',
          'img',
        ]
      },
      {
        title: '方式二：使用U盘安装',
        label: [
          <>
            <p>1.下载apk包</p>
            <a
              onClick={handleOnClick('tianmao')} 
              href="http://rel.huya.com/apk/tv?key=tv0001" 
              className="downloadBtn"
              style={{marginBottom: '0px'}}
            >
              立即下载
              <div className="hoveur"></div>
            </a>
          </>,
          '2.将apk包拷贝到U盘中，将U盘插入电视，电脑检测到U盘，点击应用',
          'img',
          '3.在电视上找到U盘中的安装包进行安装即可',
          'img',
        ]
      }
    ]
  },
  sony:{
    title: '索尼电视安装教程',
    ways: [
      {
        title: '方式一：应用市场下载安装',
        label: [
          <>在首页点击<br/>“应用”-&gt;“应用商店”，搜索“YSTHDJ”下载即可</>,
          'img',
          'img',
          'img',
        ]
      },
      {
        title: '方式二：使用U盘安装',
        label: [
          <>
            <p>1.下载apk包</p>
            <a
              onClick={handleOnClick('sony')} 
              href="http://rel.huya.com/apk/tv?key=tv0001" 
              className="downloadBtn"
              style={{marginBottom: '0px'}}
            >
              立即下载
              <div className="hoveur"></div>
            </a>
          </>,
          '2.将apk包拷贝到U盘中',
          '3.安装前需再电视中开启“未知来源”，设置：在首页点击“设置”->“安全和限制”，开启未知来源',
          'img',
          '4.开启后将U盘插入到电视的USB接口，在首页点击“应用”-“应用助手”，选中云视听虎电竞安装即可',
          'img',
          'img',
        ]
      }
    ]
  }
}

export default tvIntroduceData;