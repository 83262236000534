import { hot } from 'react-hot-loader/root';
import React, { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { handleOnClick } from './views/tvIntroduce.config';
import useBuriedPoint from './views/useBuriedPoint';

import '@styles/pages/app.scss';


const tvLogo = [
  'changhong',
  'hisense',
  'huawei',
  'konka',
  'letv',
  'mi',
  'skyworth',
  'skyworthBox',
  'sony',
  'TCL',
  'tianmao'
]

const App : FunctionComponent<any> = () => {
  const pngMap = require.context('@images/tvlogo', false, /.png$/);
  useBuriedPoint('index')

  return (
    <div id='app'>
      <div className="title">
        <img src={require('@images/title.png')} />
        <div>虎牙直播TV版， 大屏蓝光极致体验</div>
      </div>
      <img src={require('@images/tv.png')} />
      <div className="tvtext">
        <div>
          <img src={require('@images/liveIcon.png')} />
          <p>直播、视频、赛事、全都有</p>
        </div>
        <div>
          <img src={require('@images/recordIcon.png')} />
          <p>大屏、蓝光、流畅、够爽快</p>
        </div>
      </div>
      <div className="introduce">
        <h1>如何安装？</h1>
        <h2>方式一：应用市场下载安装</h2>
        <p>可在电视机/电视盒子自带的应用商店中下载，也可在第三方应用市场（如当贝市场、沙发管家）下载安装</p>
        <p>（1）在智能电视/盒子的应用商店中，搜索“云视听虎电竞”下载并安装</p>
        <img src={require('@images/way1.png')} />
        <p>（2）在第三方应用商店（如沙发管家），搜索“云视听虎电竞”下载并安装</p>
        <img src={require('@images/way2.png')} />
      </div>

      <div className="introduce">
        <h2 onClick={handleOnClick}>方式二：使用U盘安装</h2>
        <p>下载apk安装包，拷贝到U盘中，将U盘插入电视，找到U盘中的安装包安装即可</p>
        <a href="http://rel.huya.com/apk/tv?key=tv0001" className="downloadBtn" onClick={handleOnClick('index')}>
          立即下载
          <div className="hoveur" ></div>
        </a>
      </div>

      <div className="otherInteoduce">
        <h1>还是不清楚如何安装？</h1>
        <p>点击查看各品牌电视/盒子的详细安装教程</p>
        <div>
          {
            tvLogo.map((item, index) => (
              <Link to={`/introduce/${item}`}  key={index}>
                <div>
                  <img src={pngMap(`./${item}.png`)}></img>
                </div>
              </Link>
            ))
          }
        </div>
      </div>
    </div>
  )

}



// 开启 webpack 的 HMR
export default hot(App);
