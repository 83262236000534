import React, { useEffect, ReactElement }from 'react';
import '@styles/pages/introduce.scss';
import introConfig from './tvIntroduce.config';
import { useParams, Link } from "react-router-dom";
import useBuriedPoint from './useBuriedPoint';

const introPics = require.context('@images/tv', true, /.png$/);

const Introduce = () => {
  const { tvName } = useParams<{tvName: string}>()
  const { title, ways } = introConfig[tvName];
  useBuriedPoint(tvName);
  
  return (
    <div id="introduce">
      <div className="itrTitle">
        <Link to='/'>
          <img src={require('@images/title.png')} />
        </Link>
      </div>
      <h1>{title}</h1>
      {ways.map((item: any, idx: number) => {
        let imgCount = 0;
        return (
          <div className="way" key={idx}>
            <p>{item.title}</p>
            {item.label.map((it : ReactElement | string, key : number) => {
              if (it !== 'img') return <p key={key}>{it}</p>
              imgCount++;
              return <img src={introPics(`./${tvName}/${idx + 1}${imgCount}.png`)} key={key}/>
            })}
          </div>
        )
        
      } 
        
      )}
      {/* <a>立即下载</a> */}
    </div>
  );
}

export default Introduce;
